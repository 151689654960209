<template>
    <section>
        <pageLoader v-if="ploader" :loadingText="'Sites en cours de chargement...'"/>
        <div class="page-header">
            <h3 class="page-title">
                Vos applications
            </h3>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">Applications</a></li>
                </ol>
            </nav>
        </div>
        
        <!-- bouton ajouter -->
        <!-- <div class="row">
            <div class="col-12 mb-4 text-right">
                <button type="button" id="addSite" class="btn  btn-success" @click="addSite()">Créer un nouveau site</button>
            </div>
        </div> -->

        <!-- filtre et recherche -->
        <div class="row mb-2">
            <div class="col-md-12 d-flex justify-content-end align-items-center">
                <button @click="view_grid=true" type="button" class="btn btn-social-icon" :class="{'btn-twitter':view_grid}"><i class="mdi mdi-view-grid"></i></button>
                <button @click="view_grid=false" type="button" style="display:none" class="btn btn-social-icon mx-3" :class="{'btn-twitter':!view_grid}"><i class="mdi mdi-view-list "></i></button>
                <input type="text" v-model="search" placeholder="Rechercher ..." style="width:20rem" class="form-control">
            </div>
        </div>

        <!-- liste des sites: Vue GRID  -->
        <div class="row grid_view" v-show="view_grid">
            <div class="col-md-4 col-sm-12 mt-3" v-for="(item,itemid) in searchResult" :key="itemid">
                <b-card class="pointer" :img-src="item.image !== null && item.image !==''? item.image.content.webpImage:'https://monechappeebelle.fr/img/logo.fe0df23f.png'" :img-alt="`logo de ${item.name}`" img-top>
                    <b-card-text>
                        <!-- EDIT / DELETE -->
                        <label title="Editer le produit" class="badge badge-info crud edit pointer"  style="z-index:1" @click="editSite(item.ulid)"><i class="fa fa-pencil"></i></label>
                        <label title="Supprimer le produit" class="badge badge-danger crud delete pointer"  style="z-index:1" @click="deleteSite(item.ulid)"><i class="fa fa-trash"></i></label>
                        <h5 class="text-center site-title" @click="select(item), ploader=true">{{item.name}}</h5>
                    </b-card-text>
                </b-card>
            </div>
        </div>
        
        
        <!-- liste des sites: Vue LISTE  -->
        <div class="row" v-show="!view_grid">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table b-table table-striped">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Application</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,itemid) in searchResult" :key="itemid">
                                        <td><img :src="item.image !== null && item.image !==''? item.image.content.webpImage:'https://monechappeebelle.fr/img/logo.fe0df23f.png'" class="mr-2 rounded_image" alt=""></td>
                                        <td class="pointer" @click="select(item)">{{item.name}}</td>
                                        <td>
                                            <i class="fa fa-trash pointer"  @click="deleteSite(item.ulid)"></i>
                                            <i class="fa fa-edit pointer mx-4" @click="editSite(item.ulid)"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import axios from 'axios'
import {mapState} from 'vuex'
export default {
    name:"applications",
    data () {
        return {
            // loader
            ploader: true,
            items: [
                { id:"apitest", name: "ailem_2", backendurl:"https://api2.ailem.io",image:null},
                { id:"test", name: "ailem_3", backendurl:"https://api.ailem.io",image:null},
                { id:"apitest", name: "ailem_4", backendurl:"https://api2.ailem.io",image:''},
                { id:"test", name: "ailem_5", backendurl:"https://api.ailem.io",image:''},
                { id:"apitest", name: "ailem_6", backendurl:"https://api2.ailem.io",image:''}
            ],
            // axios_base_url:"https://api.ailem.io",
            view_grid: true,
            search:''
        }
    },
    computed:{
        ...mapState({
            main_api_url: state=> state.globalVariable.main_api_url
        }),
        searchResult() {
            return this.search || this.search !==""? this.items.filter(
                item => item.name.toLowerCase().includes(this.search.toLowerCase())
            )
            :this.items
        },
    },
    methods:{
        async select(item){
            console.log({item});
            // stocker les informations du site sélectionné 
            localStorage.setItem('backend', item.backendurl)
            let client_api_url = localStorage.getItem('backend')
            this.$store.commit("SET_CLIENT_API",client_api_url)
            
            localStorage.setItem('open_site', item.name)
            // authentification utilisant le OpenIdConnect
            axios.post(`${client_api_url}/oiconnect`,{
                refresh_token: localStorage.getItem('refresh_token')
            })
            .then(resOiconnect => {
                resOiconnect

                // enregistrer les informations du site

                localStorage.setItem('company', item.billingcompanies.ulid)

                // oiconnect version cookies
                // this.login(resOiconnect.data.link)
                
                // oiconnect version token
                localStorage.setItem('token',resOiconnect.data.token) 
                document.location.href="/dashboard"
            })
            .catch(errOiConnect => {
                alert(`Ce site a besoin d'être activé par les administrateurs, merci de les contacter`)
                this.ploader= false
                console.log({errOiConnect});
            })
            
        },
        login(link){
            axios.post(link)
            .then(resLink => {
                resLink
                document.location.href="/dashboard"
            })
            .catch(errLogin => {
                console.log({errLogin})
            })
        },
        getSites(url){
            axios.get(`${this.main_api_url}/${url}`, { withCredentials:true })
            .then(resGetMySites => {
                this.items = resGetMySites.data.result
                this.ploader=false
            })
            .catch(errGetMysites => console.error(errGetMysites) )
        },
        addSite(){
            this.$router.push({
            name:"addSite"
        })
       },
        deleteSite(ulid){
            axios.delete(`${this.main_api_url}/sites/${ulid}`)
            .then(resDelete => {
                resDelete
                alert('site supprimé')
            })
            .catch(errDelete => console.log(errDelete))
        },
        editSite(ulid) {
            this.$router.push({
                name:"editSite",
                params:{
                ulid:ulid
                }
            })
        },
        getCookies(){
            const cookies = document.cookie.split(';')
            console.log(cookies);
        }
    },  
    mounted(){
        let roles = localStorage.getItem('roles')
        if( roles.includes('ROLE_ADMIN_DASHBOARD') ) {
            this.getSites('sites')
            console.log('administrateur');
        }
        else if ( roles.includes('ROLE_USER_DASHBOARD') && !roles.includes('ROLE_ADMIN_DASHBOARD') ){
            this.getSites('mysites')
            console.log('simple user');
        }
        // this.getMySites()
        this.getCookies()
    }
}
</script>
<style lang="scss" scoped>
.card{
    padding: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .card-body{
            padding:1.25rem 1.25rem 0rem 1.25rem
        }
        img {
            height: 5rem;
            object-fit: cover;
        }
}

@media screen and (max-width: 992px) {
  .computer {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .mobile {
      display: none !important
  }
}

.no-border{
    border-color:#fff !important
}

// bouton filtre
.btn-social-icon:hover {
    border: 1px #2caae1 solid;
}

/* bouton CRUD */
.crud.edit{
height: 40px;
width: 40px;
border-radius: 50%;
position: absolute;
right: 34px;
top: -20px;
padding-top: 10px;
padding-left: 13px;
font-size: 17px;
}
.crud.delete{
height: 40px;
width: 40px;
border-radius: 50%;
position: absolute;
right: -13px;
top: -20px;
padding-top: 10px;
padding-left: 13px;
font-size: 17px;
}
.card .crud {
    opacity: 0;
    transition: 0.2s ease-in
}
.card:hover{
    // .crud {
    //     opacity:1
    // }
}

.card-text {
    display: inherit;
}
// .card-text:hover {
//     display: none;
// }

.rounded_image {
    width: 36px;
    height: 36px !important;
    border-radius: 100%;
    object-fit: cover !important;
}
.site-title:hover{
    text-decoration: underline !important;
    color: #2caae1;
}
</style>